import { useSelector, useDispatch } from 'react-redux';
import LoaderView from "../../components/LoaderView";
import Layout from "../../components/v2/Layout";
import Typography from "@material-ui/core/Typography";
import React from "react";

import * as Constant from '../../actions/constants';
import DownloadItemCompact from '../../components/MedicalCertificate/DownloadItemCompact';
import { getMedicalCertificateHistories } from '../../actions/medicalcertificate';
import { createLoadingSelector } from '../../reducers/api';

export default ({}) => {
  const dispatch = useDispatch();
  const loading = useSelector(createLoadingSelector([
    Constant.MEDICAL_CERTIFICATE_HISTORIES_GET_REQUEST,
  ]))
  const { medicalcertificateHistoryList } = useSelector(state => state.medicalcertificate);
  const items = medicalcertificateHistoryList;

  React.useEffect(() => {
    dispatch(getMedicalCertificateHistories())
  }, []);

  const handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, '_blank', 'noreferrer');
  }

  return (
    <Layout bg='bg3' title='ใบรับรองแพทย์'>
      <LoaderView
        loading={loading}
        empty={!loading && items.length === 0}
        emptyText='คุณยังไม่มีใบรับรองแพทย์'
        colorText='white'
      >
        <Typography
          style={{ color:'white', marginBottom: '16px' }}
          variant="h4">
          ใบรับรองแพทย์
        </Typography>
        {items.map(item => (
          <DownloadItemCompact
            datetime={item.created}
            staffName={item.staff.name}
            symptom={item.symptom}
            downloadFile={item.file}
            onDownloadClick={handleDownloadClick}
          ></DownloadItemCompact>
        ))}
      </LoaderView>
    </Layout>
  )
}