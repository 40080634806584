import * as Constant from './constants';
import * as Api from '../apis/medicalcertificate';


export const getMedicalCertificateHistories = () => {
  return dispatch => {
    dispatch({ type: Constant.MEDICAL_CERTIFICATE_HISTORIES_GET_REQUEST });
    return Api.getMedicalCertificateHistories()
      .then(payload => {
        dispatch({ type: Constant.MEDICAL_CERTIFICATE_HISTORIES_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MEDICAL_CERTIFICATE_HISTORIES_GET_FAILED, message });
      });
  };
};