import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { renderRegularDate } from '../../../helpers/date';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: 16,
    marginBottom: "16px",
  },
  details: {
    display: "flex",
    flex: "1 0 auto",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  actions: {
    padding: "16px 16px",
    width: "auto",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));


export default ({ datetime, staffName, symptom, downloadFile, onDownloadClick }) => {
  const classes = useStyles();
  const handleDownloadClick = file => {
    onDownloadClick(file);
  };

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="h6">
            {datetime 
              ? renderRegularDate(new Date(datetime))
              : '-'}
          </Typography>
          <Typography variant="body2" gutterBottom>
            { staffName }
          </Typography>
          <Typography variant="body2">
            อาการ: {symptom}
          </Typography>
        </CardContent>
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => handleDownloadClick(downloadFile)}>
          ดาวน์โหลด
        </Button>
      </div>
    </Card>
  );
}
